var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"window-header py-2 relative",class:_vm.title != _vm.$t('obj_health_records.lbl_make_an_appointment')
      ? 'border-bottom bd-e4'
      : ''},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center"},[_c('div',{staticClass:"text-center d-flex right-header",class:_vm.getHeaderClass()},[_c('p',{staticClass:"robo-24-500 txt-pri mb-1 mt-2 text-uppercase"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"d-flex cs-margin"},[(_vm.type === 'health-information' && _vm.can('update-health-info'))?_c('button',{staticClass:"btn bg-pri bd-pri text-white btn-header rounded-sm mr-2",on:{"click":function($event){return _vm.onUpdateHealthInfo()}}},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_edit"))+" ")]):_vm._e(),(_vm.type === 'health-information')?_c('button',{staticClass:"btn btn--ghost btn-header rounded-sm bd-pri txt-pri",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.saveAsTemplate()}}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm mr-2",attrs:{"role":"status"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_save_example"))+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex cs-margin"},[(
          !!_vm.care_team_doctor &&
          _vm.type === 'waiting-profile-details' &&
          _vm.care_team_doctor.is_accepted !== 1
        )?_c('button',{staticClass:"btn btn--white px-5 rounded-sm ml-auto mr-1",attrs:{"disabled":_vm.care_team_doctor.is_accepted === 1},on:{"click":function($event){return _vm.careTeamsReject()}}},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_reject"))+" ")]):_vm._e(),(
          !!_vm.care_team_doctor &&
          _vm.type === 'waiting-profile-details' &&
          _vm.care_team_doctor.is_accepted !== 1
        )?_c('button',{staticClass:"btn bg-pri bd-pri text-white px-5 rounded-sm",attrs:{"disabled":_vm.care_team_doctor.is_accepted === 1},on:{"click":function($event){return _vm.careTeamsAccept()}}},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_join"))+" ")]):_vm._e(),(
          !!_vm.care_team_doctor &&
          _vm.type === 'waiting-profile-details' &&
          _vm.care_team_doctor.is_accepted === 1
        )?_c('p',{staticClass:"robo-16-500 txt-pri mb-0"},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_joined"))+" ")]):_vm._e()]),_c('a',{staticClass:"d-flex justify-content-end absolute top-3 right-0",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.onClose()}}},[_c('img',{attrs:{"src":require("../../../public/assets/images/icon/x-gray.svg"),"alt":"","width":"26","height":"26"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }