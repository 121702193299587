<template>
  <div class="col-12 mb-3">
    <div class="row mb-3">
      <!-- bác sĩ chính -->
      <div class="col-md-4" v-if="current_incharge && !edit">
        <div class="mb-2 d-flex justify-content-between align-items-center">
          <p class="robo-16-500 text-gray mb-0">
            {{ $t("obj_health_records.lbl_main_doctor") }}
          </p>
        </div>
        <div class="dropdown show">
          <a
            class="btn bg-blue-gray w-100 d-flex align-items-center justify-content-start doctor-in-careteam"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :class="current_incharge ? 'disabled' : ''"
          >
            <!-- item đã chọn -->
            <div
              v-if="current_incharge || selected_incharge"
              class="d-flex align-items-center justify-content-start w-100"
              :set="(doctor = current_incharge)"
            >
              <span v-if="doctor && doctor.user" class="mr-3">
                <span
                  class="avatar avatar-sm avatar-rounded"
                  v-if="doctor.user.avatar"
                  :style="
                    'background-image: url(' +
                    getImageURL(doctor.user.avatar) +
                    ')'
                  "
                ></span>
                <span
                  v-if="!doctor.user.avatar"
                  class="avatar avatar-sm avatar-rounded bg-dark text-white"
                >
                  <span style="color: #fff">{{
                    doctor.name ? doctor.name.charAt(0) : ""
                  }}</span>
                </span>
              </span>
              <p class="robo-16-500 txt-pri mb-0">{{ doctor.name }}</p>
            </div>
          </a>
        </div>
      </div>
      <!-- bác sĩ chính -->

      <!-- bác sĩ điều trị -->
      <div
        class="col-md-4"
        v-if="current_treatings && current_treatings.length && !edit"
      >
        <div class="robo-16-500 text-gray mb-2">
          {{ $t("obj_health_records.lbl_treat_doctor") }}
        </div>

        <div class="dropdown show">
          <a
            class="btn bg-blue-gray doctor-in-careteam w-100 d-flex align-items-center justify-content-start"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- selected treating -->
            <div class="d-flex align-items-center w-100">
              <div
                v-for="current_treating in current_treatings"
                :key="current_treating.id + 'currTreating'"
              >
                <span v-if="current_treating" class="mr-2">
                  <span
                    class="avatar avatar-sm avatar-rounded"
                    v-if="current_treating.user && current_treating.user.avatar"
                    :style="
                      'background-image: url(' +
                      getImageURL(current_treating.user.avatar) +
                      ')'
                    "
                  ></span>
                  <span
                    v-if="
                      !current_treating.user || !current_treating.user.avatar
                    "
                    class="avatar avatar-sm avatar-rounded bg-dark text-white"
                  >
                    <span style="color: #fff">{{
                      current_treating.name
                        ? current_treating.name.charAt(0)
                        : ""
                    }}</span>
                  </span>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- bác sĩ điều trị -->

      <div
        class="col-md-4"
        v-if="current_supporters && current_supporters.length && !edit"
      >
        <div class="robo-16-500 text-gray mb-2">
          {{ $t("obj_health_records.lbl_support_doctor") }}
        </div>
        <div class="dropdown show">
          <a
            class="btn bg-blue-gray doctor-in-careteam w-100 d-flex align-items-center justify-content-start"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- selected sp -->
            <div class="d-flex align-items-center w-100">
              <div
                v-for="(current_supporter, i) in current_supporters"
                :key="current_supporter.id + 'currSp' + i"
              >
                <span v-if="current_supporter" class="mr-2">
                  <span
                    class="avatar avatar-sm avatar-rounded"
                    v-if="
                      current_supporter.user && current_supporter.user.avatar
                    "
                    :style="
                      'background-image: url(' +
                      getImageURL(current_supporter.user.avatar) +
                      ')'
                    "
                  ></span>
                  <span
                    v-if="
                      !current_supporter.user || !current_supporter.user.avatar
                    "
                    class="avatar avatar-sm avatar-rounded bg-dark text-white"
                  >
                    <span style="color: #fff">{{
                      current_supporter.name
                        ? current_supporter.name.charAt(0)
                        : ""
                    }}</span>
                  </span>
                </span>
              </div>
              <div
                v-for="selected_supporter in selected_supporters"
                :key="selected_supporter.id + 'sltTreating'"
              >
                <span v-if="selected_supporter" class="mr-2">
                  <span
                    class="avatar avatar-sm avatar-rounded"
                    v-if="
                      selected_supporter.user && selected_supporter.user.avatar
                    "
                    :style="
                      'background-image: url(' +
                      getImageURL(selected_supporter.user.avatar) +
                      ')'
                    "
                  ></span>
                  <span
                    v-if="
                      !selected_supporter.user ||
                      !selected_supporter.user.avatar
                    "
                    class="avatar avatar-sm avatar-rounded bg-dark text-white"
                  >
                    <span style="color: #fff">{{
                      selected_supporter.name
                        ? selected_supporter.name.charAt(0)
                        : ""
                    }}</span>
                  </span>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-if="edit">
      <SelectCareTeam
        :disease="null"
        :edit="true"
        :owner_clinic_sp="owner_clinic_sp"
        :selected_treatings="selected_treatings"
        :selected_incharge="selected_incharge"
        :selected_supporters="selected_supporters"
        :current_incharge="current_incharge"
        :current_supporters="current_supporters"
        :current_treatings="current_treatings"
        :err_incharge="err_incharge"
        :err_treating="err_treating"
        @selectIncharge="selectIncharge"
        @selectTreating="selectTreating"
        @selectSupporter="selectSupporter"
      />
    </div>
    <div class="row">
      <div
        class="col-12 mb-3"
        v-if="care_team_doctor && care_team_doctor.invite_message"
      >
        <label for class="robo-20-500 txt-pri mb-3">
          <svg
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.19 6H6.79C6.53 6 6.28 6.01 6.04 6.04C3.35 6.27 2 7.86 2 10.79V14.79C2 18.79 3.6 19.58 6.79 19.58H7.19C7.41 19.58 7.7 19.73 7.83 19.9L9.03 21.5C9.56 22.21 10.42 22.21 10.95 21.5L12.15 19.9C12.3 19.7 12.54 19.58 12.79 19.58H13.19C16.12 19.58 17.71 18.24 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.6 16.38 6 13.19 6ZM6.5 14C5.94 14 5.5 13.55 5.5 13C5.5 12.45 5.95 12 6.5 12C7.05 12 7.5 12.45 7.5 13C7.5 13.55 7.05 14 6.5 14ZM9.99 14C9.43 14 8.99 13.55 8.99 13C8.99 12.45 9.44 12 9.99 12C10.54 12 10.99 12.45 10.99 13C10.99 13.55 10.55 14 9.99 14ZM13.49 14C12.93 14 12.49 13.55 12.49 13C12.49 12.45 12.94 12 13.49 12C14.04 12 14.49 12.45 14.49 13C14.49 13.55 14.04 14 13.49 14Z"
              fill="#20419B"
            />
            <path
              d="M21.98 6.79V10.79C21.98 12.79 21.36 14.15 20.12 14.9C19.82 15.08 19.47 14.84 19.47 14.49L19.48 10.79C19.48 6.79 17.19 4.5 13.19 4.5L7.1 4.51C6.75 4.51 6.51 4.16 6.69 3.86C7.44 2.62 8.8 2 10.79 2H17.19C20.38 2 21.98 3.6 21.98 6.79Z"
              fill="#20419B"
            />
          </svg>
          {{ $t("obj_health_records.lbl_message_v2") }}
        </label>
        <input
          readonly
          type="text"
          class="form-control bg-blue-gray"
          :value="care_team_doctor.invite_message"
        />
      </div>
      <!-- <div class="col-12 mb-3">
        <label
          for=""
          class="robo-20-500 txt-pri my-3"
        >
          <svg
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 5.32998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 9.59377 21.8904 10.4457 21.6931 11.25H17.4684C17.3942 11.25 17.3238 11.217 17.2763 11.16L16.055 9.69448C15.2471 8.72498 13.7098 8.9034 13.1454 10.0322L11.6266 13.0698C11.5393 13.2444 11.2945 13.256 11.191 13.0905L9.92975 11.0725C9.60996 10.5608 9.04913 10.25 8.44575 10.25H2.11193C2.03885 9.74778 2 9.22808 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.37 3.09998 10.99 3.97998 12 5.32998ZM2.44243 11.75C2.46735 11.8339 2.49321 11.9172 2.52 12C4.1 17 8.97 19.99 11.38 20.81C11.72 20.93 12.28 20.93 12.62 20.81C14.907 20.0318 19.4092 17.2996 21.2131 12.75H17.4684C16.949 12.75 16.4565 12.5193 16.124 12.1203L14.9027 10.6548C14.7873 10.5163 14.5677 10.5417 14.487 10.703L12.9682 13.7406C12.3571 14.9629 10.6433 15.0444 9.91898 13.8855L8.65775 11.8675C8.61207 11.7944 8.53195 11.75 8.44575 11.75H2.44243Z"
              fill="#20419B"
            />
          </svg>
          Chẩn đoán
        </label>
        <input
          :readonly="!edit"
          type="text"
          class="form-control bg-blue-gray"
          v-model="form.diseases_name"
        />
        <small
          class="text-red"
          v-if="err_name"
        >* {{err_name}}</small>
      </div>-->
      <div class="col-12 mb-3">
        <div class="flex flex-col md:flex-row gap-4">
          <div class>
            <div class="d-flex">
              <label for class="robo-20-500 txt-pri mb-2 whitespace-nowrap">
                <svg
                  class="mr-1"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 5.32998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 9.59377 21.8904 10.4457 21.6931 11.25H17.4684C17.3942 11.25 17.3238 11.217 17.2763 11.16L16.055 9.69448C15.2471 8.72498 13.7098 8.9034 13.1454 10.0322L11.6266 13.0698C11.5393 13.2444 11.2945 13.256 11.191 13.0905L9.92975 11.0725C9.60996 10.5608 9.04913 10.25 8.44575 10.25H2.11193C2.03885 9.74778 2 9.22808 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.37 3.09998 10.99 3.97998 12 5.32998ZM2.44243 11.75C2.46735 11.8339 2.49321 11.9172 2.52 12C4.1 17 8.97 19.99 11.38 20.81C11.72 20.93 12.28 20.93 12.62 20.81C14.907 20.0318 19.4092 17.2996 21.2131 12.75H17.4684C16.949 12.75 16.4565 12.5193 16.124 12.1203L14.9027 10.6548C14.7873 10.5163 14.5677 10.5417 14.487 10.703L12.9682 13.7406C12.3571 14.9629 10.6433 15.0444 9.91898 13.8855L8.65775 11.8675C8.61207 11.7944 8.53195 11.75 8.44575 11.75H2.44243Z"
                    fill="#20419B"
                  />
                </svg>
                {{ $t("obj_health_records.lbl_disease_code") }}
              </label>
            </div>
            <el-select
              v-model="form.disease_class_code"
              filterable
              remote
              :remote-method="searchDiseaseCodes"
              :placeholder="$t('obj_health_records.lbl_disease_code')"
              @change="setName"
            >
              <el-option
                v-for="item in icds.data"
                :key="item.id"
                :label="item.code"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
          <div class="w-100">
            <div class="d-flex">
              <label for class="robo-20-500 txt-pri mb-2">
                {{ $t("obj_health_records.lbl_disease_name") }}
              </label>
            </div>
            <el-select
              v-model="form.diseases_name"
              filterable
              allow-create
              remote
              :remote-method="searchDiseaseName"
              :placeholder="$t('obj_health_records.lbl_select_name')"
              @change="setCode"
              class="cs-mobile-width"
            >
              <el-option
                v-for="item in icds.data"
                :key="item.id"
                :label="item.name"
                :value="item.name"
                class="h-auto"
              >
                <span style="word-break: break-word; white-space: pre-line">{{
                  item.name
                }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <label for class="robo-20-500 txt-pri my-3">
          <svg
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 6.5C18 9.53757 15.5376 12 12.5 12C9.46243 12 7 9.53757 7 6.5C7 6.33146 7.00758 6.16468 7.02242 6H4C2.89543 6 2 6.89543 2 8V21C2 22.1046 2.89543 23 4 23H20C21.1046 23 22 22.1046 22 21V8C22 6.89543 21.1046 6 20 6H17.9776C17.9924 6.16468 18 6.33146 18 6.5ZM4.75 17.5C4.33579 17.5 4 17.8358 4 18.25C4 18.6642 4.33579 19 4.75 19H19.25C19.6642 19 20 18.6642 20 18.25C20 17.8358 19.6642 17.5 19.25 17.5H4.75ZM6 15.25C6 14.8358 6.33579 14.5 6.75 14.5H17.25C17.6642 14.5 18 14.8358 18 15.25C18 15.6642 17.6642 16 17.25 16H6.75C6.33579 16 6 15.6642 6 15.25Z"
              fill="#20419B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17 6.5C17 8.98528 14.9853 11 12.5 11C10.0147 11 8 8.98528 8 6.5C8 4.01472 10.0147 2 12.5 2C14.9853 2 17 4.01472 17 6.5ZM11.6208 4.75C11.6208 4.33579 11.9566 4 12.3708 4C12.785 4 13.1208 4.33579 13.1208 4.75V6.38818L14.6389 6.1404C15.0477 6.07367 15.4332 6.35099 15.5 6.75979C15.5667 7.1686 15.2894 7.55409 14.8806 7.62081L12.8717 7.94867C12.7928 7.96155 12.7147 7.96161 12.6396 7.95037C12.5562 7.98243 12.4655 8 12.3708 8C11.9566 8 11.6208 7.66421 11.6208 7.25V4.75Z"
              fill="#20419B"
            />
          </svg>
          {{ $t("obj_health_records.lbl_disease_progression") }}
        </label>
        <textarea
          :readonly="!edit"
          class="form-control bg-blue-gray"
          :placeholder="
            $t('obj_health_records.lbl_how_does_the_disease_progress')
          "
          v-model="form.diseases_stage"
          style="height: 100px"
        ></textarea>
      </div>
      <div class="col-12 mb-3">
        <div class="flex flex-col md:flex-row gap-2 mb-2 md:items-center">
          <label for class="robo-20-500 txt-pri my-3 d-flex align-items-center">
            <svg
              class="mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 6.5C18 9.53757 15.5376 12 12.5 12C9.46243 12 7 9.53757 7 6.5C7 6.33146 7.00758 6.16468 7.02242 6H4C2.89543 6 2 6.89543 2 8V21C2 22.1046 2.89543 23 4 23H20C21.1046 23 22 22.1046 22 21V8C22 6.89543 21.1046 6 20 6H17.9776C17.9924 6.16468 18 6.33146 18 6.5ZM4.75 17.5C4.33579 17.5 4 17.8358 4 18.25C4 18.6642 4.33579 19 4.75 19H19.25C19.6642 19 20 18.6642 20 18.25C20 17.8358 19.6642 17.5 19.25 17.5H4.75ZM6 15.25C6 14.8358 6.33579 14.5 6.75 14.5H17.25C17.6642 14.5 18 14.8358 18 15.25C18 15.6642 17.6642 16 17.25 16H6.75C6.33579 16 6 15.6642 6 15.25Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17 6.5C17 8.98528 14.9853 11 12.5 11C10.0147 11 8 8.98528 8 6.5C8 4.01472 10.0147 2 12.5 2C14.9853 2 17 4.01472 17 6.5ZM11.6208 4.75C11.6208 4.33579 11.9566 4 12.3708 4C12.785 4 13.1208 4.33579 13.1208 4.75V6.38818L14.6389 6.1404C15.0477 6.07367 15.4332 6.35099 15.5 6.75979C15.5667 7.1686 15.2894 7.55409 14.8806 7.62081L12.8717 7.94867C12.7928 7.96155 12.7147 7.96161 12.6396 7.95037C12.5562 7.98243 12.4655 8 12.3708 8C11.9566 8 11.6208 7.66421 11.6208 7.25V4.75Z"
                fill="#20419B"
              />
            </svg>
            {{ $t("obj_health_records.lbl_current_status") }}
          </label>

          <a
            v-if="type === 'health-information' && can('view-health-history')"
            href="javascript:;"
            class="robo-16-500 txt-pri"
            @click="onShowProfileHistory()"
          >
            <img
              src="../../../public/assets/images/icon/rotate-left.svg"
              width="24"
              height="24"
              alt
            />
            {{ $t("obj_health_records.lbl_history") }}
          </a>
        </div>
        <textarea
          :readonly="!edit"
          class="form-control bg-blue-gray"
          v-model="form.diseases_current_cure"
          style="height: 200px"
        ></textarea>
      </div>
      <div class="col-12 mb-3">
        <div class="flex flex-col md:flex-row gap-2 mb-2 md:items-center">
          <label for class="robo-20-500 txt-pri my-3 d-flex align-items-center">
            <svg
              class="mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.2125 22.5H4.3875H7.10625H10.9125C11.5133 22.5 12 22.8356 12 23.25C12 23.6644 11.5133 24 10.9125 24H2.2125C1.61166 24 1.125 23.6644 1.125 23.25C1.125 22.8356 1.61166 22.5 2.2125 22.5Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.6228 8.65593C19.9423 8.2054 19.836 7.58122 19.3855 7.26177L14.491 3.7913C14.0405 3.47185 13.4163 3.57811 13.0969 4.02864L7.31277 12.1861L19.0444 9.47167L19.6228 8.65593ZM6.44515 13.4097L6.15594 13.8176C5.8365 14.2681 5.94275 14.8923 6.39328 15.2117L11.2877 18.6822C11.7383 19.0017 12.3625 18.8954 12.6819 18.4449L18.1768 10.6953L6.44515 13.4097Z"
                fill="#20419B"
              />
              <rect
                x="17.0422"
                y="1.92236"
                width="4"
                height="5"
                rx="1"
                transform="rotate(35.3389 17.0422 1.92236)"
                fill="#20419B"
              />
              <path
                d="M10.4571 13.8026C10.6169 13.5774 10.9289 13.5243 11.1542 13.684C11.3795 13.8437 11.4326 14.1558 11.2729 14.3811L5.05028 23.1569C4.89056 23.3822 4.57846 23.4353 4.3532 23.2756C4.12794 23.1159 4.07481 22.8038 4.23453 22.5785L10.4571 13.8026Z"
                fill="#20419B"
              />
              <rect
                x="22.5151"
                y="4.57715"
                width="2"
                height="8"
                rx="1"
                transform="rotate(125.339 22.5151 4.57715)"
                fill="#20419B"
              />
            </svg>
            {{ $t("obj_health_records.lbl_treatment") }}
          </label>
          <a
            v-if="type === 'health-information' && can('view-health-history')"
            href="javascript:;"
            class="robo-16-500 txt-pri"
            @click="onShowProfileHistory()"
          >
            <img
              src="../../../public/assets/images/icon/rotate-left.svg"
              width="24"
              height="24"
              alt
            />
            {{ $t("obj_health_records.lbl_history") }}
          </a>
        </div>
        <textarea
          :readonly="!edit"
          class="form-control bg-blue-gray"
          v-model="form.diseases_previous_cure"
          style="height: 200px"
        ></textarea>
      </div>
      <div class="col-12 mb-3">
        <label for class="robo-20-500 txt-pri my-3 d-flex align-items-center">
          <svg
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_14272_101952)">
              <path
                d="M6.75366 7.49268C7.16788 7.49268 7.50366 7.15689 7.50366 6.74268C7.50366 6.32846 7.16788 5.99268 6.75366 5.99268C6.33945 5.99268 6.00366 6.32846 6.00366 6.74268C6.00366 7.15689 6.33945 7.49268 6.75366 7.49268Z"
                fill="#20419B"
              />
              <path
                d="M2.625 14.7188H14.25V16.5938C14.25 17.2151 13.7464 17.7188 13.125 17.7188H3.75C3.12863 17.7188 2.625 17.2151 2.625 16.5938V14.7188Z"
                fill="#E3FCFD"
              />
              <path
                d="M21.1168 1.29272L22.7079 2.88385C23.1474 3.32335 23.1474 4.03547 22.7079 4.47497L17.5741 9.60872C17.1346 10.0482 16.4225 10.0482 15.983 9.60872L14.3919 8.0176C13.9524 7.5781 13.9524 6.86597 14.3919 6.42647L19.5256 1.29272C19.9651 0.853598 20.6776 0.853598 21.1168 1.29272Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.9837 2.07764L23.7787 1.27889C24.0693 0.986387 24.0693 0.513887 23.7787 0.221387C23.4862 -0.0692383 23.0137 -0.0692383 22.7212 0.221387L21.9224 1.01639L21.6599 0.750137C20.9279 0.0181367 19.7407 0.0181367 19.0087 0.750137L13.8749 5.88389C13.1429 6.61589 13.1429 7.80314 13.8749 8.53514L15.4649 10.1251C16.1969 10.8571 17.3842 10.8571 18.1162 10.1251L20.1299 8.10764L21.2324 7.00889L23.2499 4.99139C23.9819 4.25939 23.9819 3.07214 23.2499 2.34014L22.9837 2.07764ZM17.0549 9.06764L22.1887 3.93014C22.3342 3.78389 22.3342 3.54764 22.1887 3.40139L20.5987 1.81139C20.4524 1.66589 20.2162 1.66589 20.0699 1.81139L14.9324 6.94514C14.7869 7.09139 14.7869 7.32764 14.9324 7.47389L16.5224 9.06764L16.5247 9.06989C16.6717 9.21576 16.9091 9.21464 17.0549 9.06764Z"
                fill="#20419B"
              />
              <path
                d="M4.875 23.25C4.875 23.6644 5.21062 24 5.625 24H18C18.4144 24 18.75 23.6644 18.75 23.25C18.75 22.8356 18.4144 22.5 18 22.5H5.625C5.21062 22.5 4.875 22.8356 4.875 23.25Z"
                fill="#20419B"
              />
              <path
                d="M3 7.5H3.82875C3.924 7.97025 4.11 8.41725 4.37625 8.81625L3.975 9.21375C3.68213 9.50663 3.68213 9.98212 3.975 10.275C4.26788 10.5679 4.74338 10.5679 5.03625 10.275L5.44125 9.87C5.83763 10.1359 6.28237 10.3219 6.75 10.4175V11.625C6.75 12.0394 7.08563 12.375 7.5 12.375C7.91437 12.375 8.25 12.0394 8.25 11.625V10.425C8.72025 10.3297 9.16725 10.1438 9.56625 9.8775L9.975 10.275C10.2679 10.5679 10.7434 10.5679 11.0363 10.275C11.3291 9.98212 11.3291 9.50663 11.0363 9.21375L10.6313 8.80875C10.8968 8.412 11.0828 7.96763 11.1788 7.5H11.25C11.6644 7.5 12 7.16437 12 6.75C12 6.33563 11.6644 6 11.25 6H11.175C11.0797 5.53088 10.8938 5.085 10.6275 4.6875L11.4788 3.83625C11.7716 3.54337 11.7716 3.06788 11.4788 2.775C11.1859 2.48213 10.7104 2.48213 10.4175 2.775L9.57 3.61875C9.16988 3.35175 8.72137 3.16575 8.25 3.07125V1.875C8.25 1.46062 7.91437 1.125 7.5 1.125C7.08563 1.125 6.75 1.46062 6.75 1.875V3.07125C6.28088 3.1665 5.835 3.3525 5.4375 3.61875L5.4075 3.58875C5.1 3.31125 4.62562 3.33525 4.34812 3.64275C4.08975 3.92887 4.0905 4.36462 4.35 4.65L4.38 4.68C4.11263 5.07975 3.92663 5.52825 3.8325 6H3C2.58562 6 2.25 6.33563 2.25 6.75C2.25 7.16437 2.58562 7.5 3 7.5ZM7.5 5.625C7.91437 5.625 8.25 5.28937 8.25 4.875V4.63125C9.14813 4.94888 9.74888 5.7975 9.75 6.75C9.75075 7.08638 9.675 7.41825 9.52875 7.72125L9.375 7.54125C9.06038 7.272 8.58675 7.30837 8.3175 7.623C8.07713 7.90387 8.07713 8.31787 8.3175 8.59875L8.49 8.77125C8.18212 8.92275 7.84312 9.00112 7.5 9C6.25725 8.99587 5.25337 7.98563 5.25712 6.74288C5.26012 5.79488 5.85713 4.95038 6.75 4.63125V4.875C6.75 5.28937 7.08563 5.625 7.5 5.625Z"
                fill="#20419B"
              />
              <path
                d="M7 20.9096H6.25C6.25 21.1946 6.41154 21.455 6.6669 21.5816L7 20.9096ZM21.2645 6.61577C21.0523 6.26004 20.5919 6.14369 20.2361 6.3559C19.8804 6.56811 19.7641 7.02851 19.9763 7.38423L21.2645 6.61577ZM7.75 17.5C7.75 17.0858 7.41421 16.75 7 16.75C6.58579 16.75 6.25 17.0858 6.25 17.5H7.75ZM21.25 12C21.25 17.087 16.9906 21.25 11.6875 21.25V22.75C17.7752 22.75 22.75 17.9586 22.75 12H21.25ZM11.6875 21.25C10.117 21.25 8.63766 20.8843 7.3331 20.2376L6.6669 21.5816C8.1751 22.3292 9.88211 22.75 11.6875 22.75V21.25ZM19.9763 7.38423C20.7869 8.74303 21.25 10.3186 21.25 12H22.75C22.75 10.0386 22.2088 8.1987 21.2645 6.61577L19.9763 7.38423ZM7.75 20.9096V17.5H6.25V20.9096H7.75Z"
                fill="#20419B"
              />
              <path
                d="M2 16C2 14.8954 2.89543 14 4 14H13C14.1046 14 15 14.8954 15 16C15 17.1046 14.1046 18 13 18H4C2.89543 18 2 17.1046 2 16Z"
                fill="#20419B"
              />
              <path
                d="M0 14.75C0 14.3358 0.335786 14 0.75 14H16.25C16.6642 14 17 14.3358 17 14.75C17 15.1642 16.6642 15.5 16.25 15.5H0.75C0.335786 15.5 0 15.1642 0 14.75Z"
                fill="#20419B"
              />
            </g>
            <defs>
              <clipPath id="clip0_14272_101952">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {{ $t("obj_health_records.lbl_other_diseases") }}
        </label>
        <textarea
          :readonly="!edit"
          class="form-control bg-blue-gray"
          v-model="form.background_diseases"
          style="height: 200px"
        ></textarea>
      </div>
      <div
        class="col-12 text-center mt-3"
        v-if="
          edit &&
          ((disease && can('update-health-info')) ||
            (person && can('create-careplan') && can('assign-careteam')))
        "
      >
        <button
          class="btn bg-pri bd-pri text-white px-5"
          @click="onSubmit()"
          :disabled="loading"
        >
          {{ $t(disease ? "obj_health_records.lbl_update" : "lbl_create") }}

          <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            v-if="loading"
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import SelectCareTeam from '../../components/Diseases/SelectCareTeam'
import _ from 'lodash'
export default {
  props: ['disease', 'edit', 'person', 'type', 'care_team_doctor'],
  name: 'InfomationForm',
  components: { SelectCareTeam },
  data () {
    return {
      search: '',
      icds: [],
      icds10: [],
      loading: false,
      doctors: [],
      current_incharge: null,
      selected_incharge: null,
      current_supporters: [],
      selected_supporters: [],
      open_sl_incharge: false,
      open_sl_treating: false,
      open_sl_support: false,
      owner_clinic_sp: [],
      care_team: null,
      err_incharge: '',
      err_treating: '',
      my_doctor_info: null,
      err_name: '',
      form: {
        disease_class_code: '',
        person_id: '',
        person_diseases_id: '',
        diseases_name: '',
        diseases_stage: '',
        diseases_current_cure: '',
        diseases_previous_cure: '',
        background_diseases: '',
        treatment_summary: ''
      },
      selected_treatings: [],
      current_treatings: []
    }
  },
  watch: {
    disease: function (new_value, old_value) {
      let self = this
      self.selected_treatings = []
      self.selected_supporters = []
      self.form = {
        person_diseases_id: new_value.person_diseases_id || '',
        diseases_name: new_value.diseases_name || '',
        disease_class_code: new_value.disease_class_code || '',
        diseases_stage: new_value.diseases_stage || '',
        diseases_previous_cure: new_value.diseases_previous_cure || '',
        diseases_current_cure: new_value.diseases_current_cure || '',
        background_diseases: new_value.background_diseases || '',
        treatment_summary: new_value.treatment_summary || ''
      }
      let care_team = new_value.care_team
      if (
        care_team &&
        care_team.care_team_doctor &&
        care_team.care_team_doctor?.length
      ) {
        let care_team_doctor = care_team.care_team_doctor
        let incharge = null
        let treatings = []
        let supporters = []
        let owner_clinic_sp = []
        care_team_doctor.forEach((dr) => {
          if (parseInt(dr.role) === 1) {
            incharge = dr.doctor
          }
          if (parseInt(dr.role) === 2) {
            supporters.push(dr.doctor)
          }
          if (parseInt(dr.role) === 3) {
            owner_clinic_sp.push(dr.doctor)
          }
          if (parseInt(dr.role) === 4) {
            treatings.push(dr.doctor)
          }
        })
        self.current_incharge = incharge
        self.current_supporters = supporters
        self.owner_clinic_sp = owner_clinic_sp
        self.current_treatings = treatings
      }
    },
    person: function (new_value, old_value) {
      let self = this
      self.form = {
        person_id: new_value.id || ''
      }
    }
  },
  mounted () {
    let self = this
    self.addMeToCareTeam()
    self.getClinicDoctor()
    self.getIcd10()
    self.getIcd10Name()
    // self.autoResizeTextarea()
  },
  methods: {
    searchDiseaseCodes (value) {
      return this.getIcd10(value)
    },
    searchDiseaseName (value) {
      return this.getIcd10Name(value)
    },
    setName (value) {
      const item = _.find(this.icds.data, ['code', value])
      console.log('setName', item)
      this.form.diseases_name = item?.name
    },
    setCode (value) {
      const item = _.find(this.icds.data, ['name', value])
      console.log('code', item)
      this.form.disease_class_code = item?.code
    },
    async getIcd10 (value) {
      const p = {
        limit: 50,
        code: value || this.form.disease_class_code
      }
      const res = await this.$rf.getRequest('AuthRequest').getIcd10(p)
      this.icds = res?.data
    },
    async getIcd10Name (value) {
      const p = {
        limit: 50,
        name: value || this.form.diseases_name
      }
      const res = await this.$rf.getRequest('AuthRequest').getIcd10(p)
      this.icds = res?.data
    },
    addMeToCareTeam () {
      let self = this
      let user = appUtils.getLocalUser()
      if (user?.doctor?.type) {
        self.selected_supporters.push(user?.doctor)
      } else {
        self.selected_treatings.push(user?.doctor)
      }
    },
    async getClinicDoctor () {
      let self = this
      let clinic_id = this.$clinicId
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getClinicDoctor(clinic_id, { limit: 100 })
          .then((res) => {
            if (res && res.data && res.data.count) {
              self.doctors = res.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    openInchargeDoctor () {
      let self = this
      setTimeout(
        () => {
          if (self.current_incharge) {
            self.open_sl_incharge = false
          } else {
            self.open_sl_incharge = !self.open_sl_incharge
          }
        },
        self.open_sl_incharge ? 200 : 0
      )
    },
    openTreatingDoctor () {
      let self = this
      setTimeout(
        () => {
          if (self.current_treatings) {
            self.open_sl_treating = false
          } else {
            self.open_sl_treating = !self.open_sl_treating
          }
        },
        self.open_sl_treating ? 200 : 0
      )
    },
    closeTreatingDoctor () {
      setTimeout(() => {
        this.open_sl_treating = false
      }, 200)
    },
    closeInchargeDoctor () {
      setTimeout(() => {
        this.open_sl_incharge = false
      }, 200)
    },
    openSupportDoctor () {
      let self = this
      setTimeout(() => {
        self.open_sl_support = !self.open_sl_support
      }, 100)
    },
    selectIncharge (clinic_doctor) {
      let self = this
      let doctor = clinic_doctor.doctor
      self.err_incharge = ''
      if (!clinic_doctor || !clinic_doctor.doctor) return
      // check trùng bs chính
      if (
        !!self.selected_incharge &&
        clinic_doctor.doctor.id === self.selected_incharge.id
      ) {
        self.selected_incharge = null
      } else {
        // check trùng bs điều trị
        self.selected_incharge = clinic_doctor.doctor
        let treatingIndex = self.selected_treatings
          ? self.selected_treatings.findIndex((dr) => dr.id === doctor.id)
          : -1
        if (treatingIndex > -1) {
          self.selected_treatings.splice(treatingIndex, 1)
        }
        // check trùng hỗ trợ
        let spIndex = self.selected_supporters
          ? self.selected_supporters.findIndex(
            (dr) => dr.id === clinic_doctor.doctor.id
          )
          : -1
        if (spIndex > -1) {
          self.selected_supporters.splice(spIndex, 1)
        }
      }
    },
    selectTreating (clinic_doctor) {
      let self = this
      let doctor = clinic_doctor.doctor
      self.err_treating = ''
      // check trùng bs chính
      if (
        !!self.selected_incharge &&
        clinic_doctor.doctor.id === self.selected_incharge.id
      ) {
        self.selected_incharge = null
      }
      // check trùng bs điều trị
      let treatingIndex = self.selected_treatings
        ? self.selected_treatings.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (treatingIndex > -1) {
        self.selected_treatings.splice(treatingIndex, 1)
      } else {
        self.selected_treatings.push(doctor)
      }
      // check trùng hỗ trợ
      let spIndex = self.selected_supporters
        ? self.selected_supporters.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (spIndex > -1) {
        self.selected_supporters.splice(spIndex, 1)
      }
    },
    selectSupporter (doctor) {
      let self = this
      // check trùng bs chính
      if (!!self.selected_incharge && doctor.id === self.selected_incharge.id) {
        self.selected_incharge = null
      }
      // check trùng bs điều trị
      let treatingIndex = self.selected_treatings
        ? self.selected_treatings.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (treatingIndex > -1) {
        self.selected_treatings.splice(treatingIndex, 1)
      }
      let slt = self.selected_supporters
        ? self.selected_supporters.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (slt > -1) {
        self.selected_supporters.splice(slt, 1)
      } else {
        self.selected_supporters.push(doctor)
      }
    },
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    canChangeSupport () {
      if (!this.disease) return true
      if (!this.current_incharge && !this.current_treatings) return false
      let user = appUtils.getLocalUser()
      return (
        user.doctor &&
        ((!!this.current_incharge &&
          this.current_incharge.id === user.doctor.id) ||
          (!!this.current_treatings &&
            !!this.current_treatings.find((ct) => user.doctor.id === ct.id)))
      )
    },
    async onSubmit () {
      let self = this
      // if (!self.form.diseases_name) {
      //   self.err_name = 'Vui lòng nhập tên'
      //   return false
      // } else {
      self.loading = true
      await self
        .onSaveDiseases()
        .then((resp_id) => {
          self.loading = false
          if (resp_id) {
            self.finishUpdate(resp_id)
          } else {
            self.$router.push({ path: `/doctor/diseases` })
          }
        })
        .catch((e) => {
          self.loading = false
        })
      // }
    },
    finishUpdate (id) {
      let self = this
      self.$router.push({ path: `/doctor/diseases/${id}` }).catch((_) => {})
    },
    async onSaveDiseases () {
      let self = this
      let params = self.form
      if (!params.disease_class_code) {
        params.disease_class_code = null
      }
      if (self.disease && self.disease.id) {
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .personsDiseasesUpdate(self.disease.id, params)
          .catch((e) => {
            self.loading = false
          })
        if (self.canChangeSupport()) {
          await self.updateCareTeam(resp.data?.id, resp.data?.care_team?.id)
        }
        return resp.data.id
      } else {
        if (self.selected_incharge) {
          params.doctor_id = self.selected_incharge.id
        }
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .personsDiseasesCreate(params)
          .catch((e) => {
            self.loading = false
          })
        if (self.canChangeSupport()) await self.updateCareTeam(resp.data.id)
        return resp.data.id
      }
    },
    async updateCareTeam (pdid, ctid) {
      let self = this
      let params = {}
      let clinic_id = this.$clinicId
      if (self.selected_incharge) {
        params.main_doctor = {
          doctor_id: self.selected_incharge.id,
          clinic_id: clinic_id
        }
      }
      let treaters = []
      let selected_treatings = self.selected_treatings
      if (selected_treatings && selected_treatings.length) {
        selected_treatings.forEach((sp) => {
          treaters.push({
            doctor_id: sp.id,
            clinic_id: clinic_id
          })
        })
      }
      if (treaters) {
        params.follow_doctors = treaters
      }

      let supporters = []
      let selected_supporters = self.selected_supporters
      if (selected_supporters && selected_supporters.length) {
        selected_supporters.forEach((sp) => {
          supporters.push({
            doctor_id: sp.id,
            clinic_id: clinic_id
          })
        })
      }
      if (supporters) {
        params.supporter_doctors = supporters
      }
      let has_me = self.hasMe()
      if (!has_me) {
        let user = appUtils.getLocalUser()
        if (user?.doctor.type) {
          params.supporter_doctors.push({
            doctor_id: user.doctor.id,
            clinic_id: clinic_id
          })
        } else {
          params.follow_doctors.push({
            doctor_id: user.doctor.id,
            clinic_id: clinic_id
          })
        }
      }
      try {
        if (!ctid) {
          params.person_diseases_id = pdid
          return self.createCareTeam3(params)
        } else {
          return self.updateCareTeam3(ctid, params)
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async updateCareTeam3 (id, params) {
      let self = this

      await self.$rf
        .getRequest('DoctorRequest')
        .updateCareTeam3(id, params)
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          self.loading = false
        })
    },
    async createCareTeam3 (params) {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .createCareTeam3(params)
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          self.loading = false
        })
    },
    onShowProfileHistory () {
      let self = this
      self.$emit('onShowProfileHistory')
    },
    hasMe () {
      let self = this
      let user = appUtils.getLocalUser()
      let has_this_id = false
      let dr_id = -1
      if (user.doctor && user.doctor.id) {
        dr_id = user.doctor.id
      }
      if (
        (self.selected_incharge && dr_id === self.selected_incharge.id) ||
        (self.current_incharge && dr_id === self.current_incharge.id)
      ) {
        has_this_id = true
      } else if (
        (self.selected_treatings && dr_id === self.selected_treatings.id) ||
        (self.current_treatings && dr_id === self.current_treatings.id)
      ) {
        has_this_id = true
      } else if (self.selected_supporters && self.selected_supporters.length) {
        let is_sp = !!self.selected_supporters.find((dr) => dr.id === dr_id)
        if (is_sp) {
          has_this_id = true
        }
      } else if (self.current_supporters && self.current_supporters.length) {
        let is_sp = !!self.current_supporters.find((dr) => dr.id === dr_id)
        if (is_sp) {
          has_this_id = true
        }
      }
      return has_this_id
    }
  }
}
</script>
<style lang="css" scoped>
.relative {
  position: relative;
  z-index: 9;
}

.item-container {
  position: absolute;
  background-color: #f2f5fa;
  width: 100%;
  z-index: 10;
}

.item-doctor {
  cursor: pointer;
  position: relative;
}

.item-doctor:hover {
  background-color: white;
}

.current-support:hover {
  background-color: #f2f5fa !important;
  cursor: not-allowed !important;
}

.current-support:after {
  content: "";
  background-image: url("../../../public/assets/images/icon/icon-tick-square.svg");
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 15px;
  position: absolute;
  right: 10px;
  top: 0;
}

.selected-item-doctor:after {
  content: "";
  background-image: url("../../../public/assets/images/icon/icon-tick-square.svg");
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 15px;
  position: absolute;
  right: 10px;
  top: 0;
}

.form-control {
  color: #242424;
  font-family: "roboto";
  font-size: 16px;
  font-weight: 400;
}
a:hover {
  text-decoration: none;
}
.doctor-in-careteam {
  height: 48px;
  overflow: hidden;
  border: none;
  pointer-events: none;
}

@media (max-width: 640px) {
  .cs-mobile-width {
    max-width: 300px;
  }
}
</style>