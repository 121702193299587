<template>
  <div>
    <div class="mb-2 font-semibold">{{title}}</div>
    <el-checkbox-group v-model="checkList">
      <div
        v-if="documents && documents.length"
        class="flex flex-wrap gap-2"
      >
        <div
          v-for="doc in documents"
          :key="doc.id"
          class="w-40 relative"
        >
          <el-checkbox
            :label="doc.id"
            class="absolute top-1 left-1"
          />
          <div class="absolute top-1 right-1">
            <el-button
              :type="checkMarkDone(doc.id) ? 'success' : ''"
              circle
              size="mini"
              icon="el-icon-collection-tag"
              @click="toggleMarkDone(doc.id)"
            ></el-button>
          </div>
          <div
            class="flex flex-col"
            :title="doc.name"
          >
            <a
              target="_blank"
              :href="doc.link"
              class="no-underline"
            >
              <img
                v-if="isImage(doc.name)"
                :src="doc.link"
                :alt="doc.name"
                class="cursor-pointer bg-black w-40 h-40 object-contain"
              />
              <img
                v-else
                src="../../../public/assets/images/icon/dowload-file.svg"
                :alt="doc.name"
                class="cursor-pointer bg-black w-40 h-40 object-contain"
              />
            </a>
            <div class="text-sm font-medium truncate">{{doc.name}}</div>
            <div class="text-sm font-medium truncate">{{formatDateDMY(doc.created_at)}}</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-sm"
      >Chưa có tài liệu</div>
    </el-checkbox-group>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'DocumentSelect',
  props: ['value', 'person_id', 'person_diseases_id', 'title'],
  data () {
    return {
      loading: false,
      documents: [],
      markDone: []
    }
  },
  computed: {
    checkList: {
      get () {
        return this.value || []
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    person_id: {
      handler (id) {
        id && this.getDocuments({ person_id: id, is_processing: true, sort: 'created_at', sort_by: 'desc' })
      },
      immediate: true
    },
    person_diseases_id: {
      handler (id) {
        id && this.getDocuments({ person_diseases_id: id, is_processing: true, sort: 'created_at', sort_by: 'desc' })
      },
      immediate: true
    }
  },
  methods: {
    async getDocuments (params) {
      try {
        const resp = await this.$rf.getRequest('DoctorRequest').getDocuments(params)
        this.documents = _.map(resp.data.data, item => ({
          ...item,
          link: appUtils.getDocumentURL(item.link)
        }))
      } catch (e) {
        console.log(e)
      }
    },
    isImage (file_name) {
      return (file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null)
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    toggleMarkDone (id) {
      const temp = _.cloneDeep(this.markDone)
      if (temp.length) {
        const result = _.remove(temp, item => {
          return item === id
        })
        if (!result.length) temp.push(id)
        this.markDone = temp
      } else this.markDone.push(id)

      this.$emit('markDone', this.markDone)
    },
    checkMarkDone (id) {
      const result = this.markDone.find(item => {
        return item === id
      })
      return result
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-checkbox__label {
    display: none;
  }
  .el-checkbox,
  .el-checkbox__input {
    position: absolute;
  }
}
</style>