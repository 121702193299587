var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.list_topics && _vm.list_topics.count && !_vm.loading)?_c('div',[_vm._l((_vm.list_topics.data),function(topic){return _c('div',{key:topic.id,staticClass:"d-flex flex-wrap justify-content-start align-items-center w-100 mb-3 radius-10 cursor-pointer",class:!topic.doctor_read ? 'bg-bold-item' : 'bg-fa',staticStyle:{"padding":"15px"},on:{"click":function($event){return _vm.goToDetail(topic)}}},[_c('div',{staticClass:"left-side-item"},[_c('div',{staticClass:"icon--item",class:topic.doctor_read ? 'bg-bold-item' : 'bg-white'},[(
              topic.type === 1 ||
              topic.type === 2 ||
              topic.type === 3 ||
              topic.type === 4 ||
              topic.type === 5 ||
              topic.type === 9
            )?_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-topic-diseases.svg"),"width":"40","height":"40"}}):_vm._e(),(topic.type === 8 || topic.type === 12)?_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-topic-chat.svg"),"width":"40","height":"40"}}):_vm._e(),(
              (topic.type === 6 || topic.type === 7) &&
              topic.attach &&
              topic.attach.method &&
              topic.attach.method == 2
            )?_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-topic-video.svg"),"width":"40","height":"40"}}):_vm._e(),(
              (topic.type === 6 || topic.type === 7) &&
              topic.attach &&
              topic.attach.method &&
              topic.attach.method == 1
            )?_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-topic-walk.svg"),"width":"40","height":"40"}}):_vm._e(),(topic.type === 10 || topic.type === 11)?_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-topic-heart.svg"),"width":"40","height":"40"}}):_vm._e()])]),_c('div',{staticClass:"right-side-item"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-md-10 d-flex align-items-center"},[_c('span',{staticClass:"cmt-count"},[_vm._v("("+_vm._s(topic.comments_count || 0)+")")]),_c('div',{staticClass:"flex flex-col"},[_c('p',{staticClass:"mb-0 txt-black text-concat w-full",class:topic.doctor_read ? 'robo-16-400' : 'robo-16-500',attrs:{"set":(_vm.title = _vm.getTitle(topic))}},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.title && topic.content)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',[_vm._v(_vm._s(topic.content))])]),(
                  (topic.type === 6 || topic.type === 7) &&
                  topic.attach &&
                  topic.attach.method &&
                  topic.attach.method == 2 &&
                  topic.attach.consultation
                )?_c('div',{staticClass:"cursor-pointer d-flex pdf-file align-items-center mt-1.5"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../../../public/assets/images/icon/pdf-icon.svg"),"alt":"","width":"24","height":"24"}}),_c('span',{staticClass:"robo-14-400"},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_consulting_form"))+" "+_vm._s(_vm.formatD(topic.attach.start_time))+" ")])]):_vm._e()])]),_c('div',{staticClass:"col-md-2 d-flex flex-wrap justify-content-end align-items-center mb-2 mb-md-0 mt-2 mt-md-0"},[_c('p',{staticClass:"mb-0",class:topic.doctor_read ? 'robo-16-400' : 'robo-16-500'},[_vm._v(" "+_vm._s(_vm.formatDMY(topic.created_at))+" ")])])])])])}),_c('Pagination',{attrs:{"items":_vm.list_topics,"current_page":_vm.current_page},on:{"onRefresh":_vm.onRefresh}})],2):_vm._e(),_c('div',{staticClass:"row"},[(_vm.list_topics && !_vm.list_topics.count && !_vm.loading)?_c('div',{staticClass:"col-md-12 d-flex justify-content-center"},[_c('div',{attrs:{"align":"center"}},[_vm._m(0),(!_vm.appUtils.isAdmin())?_c('button',{staticClass:"mt-3 btn--blue btn-l d-flex justify-content-start",staticStyle:{"width":"300px"},on:{"click":function($event){return _vm.onShowModalAppt()}}},[_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-tele-gray.svg"),"alt":""}}),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_book_appt"))+" ")])]):_vm._e(),(!_vm.appUtils.isAdmin())?_c('button',{staticClass:"mb-3 mt-3 btn--blue btn-l d-flex justify-content-start",staticStyle:{"width":"300px"},on:{"click":function($event){return _vm.goToCreate()}}},[_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-exchange-gray.svg"),"alt":""}}),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.$t("obj_health_records.lbl_create_new_exchange"))+" ")])]):_vm._e()])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"col-12 mb-5"},[_vm._m(1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-3"},[_c('img',{attrs:{"src":require("../../../public/assets/images/icon/icon-new-diary.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"skeleton-avatar"})]),_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})]),_c('div',{staticClass:"col-2 ms-auto text-end"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})])])]),_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"skeleton-avatar"})]),_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})]),_c('div',{staticClass:"col-2 ms-auto text-end"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})])])]),_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"skeleton-avatar"})]),_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})]),_c('div',{staticClass:"col-2 ms-auto text-end"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})])])]),_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"skeleton-avatar"})]),_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})]),_c('div',{staticClass:"col-2 ms-auto text-end"},[_c('div',{staticClass:"skeleton-line"}),_c('div',{staticClass:"skeleton-line"})])])])])])
}]

export { render, staticRenderFns }