<template>
  <div
    class="window-header py-2 relative"
    :class="
      title != $t('obj_health_records.lbl_make_an_appointment')
        ? 'border-bottom bd-e4'
        : ''
    "
  >
    <div class="d-flex flex-column flex-md-row align-items-center">
      <div class="text-center d-flex right-header" :class="getHeaderClass()">
        <p class="robo-24-500 txt-pri mb-1 mt-2 text-uppercase">{{ title }}</p>
      </div>
      <div class="d-flex cs-margin">
        <button
          v-if="type === 'health-information' && can('update-health-info')"
          class="btn bg-pri bd-pri text-white btn-header rounded-sm mr-2"
          @click="onUpdateHealthInfo()"
        >
          {{ $t("obj_health_records.lbl_edit") }}
        </button>
        <button
          v-if="type === 'health-information'"
          class="btn btn--ghost btn-header rounded-sm bd-pri txt-pri"
          @click="saveAsTemplate()"
          :disabled="loading"
        >
          <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            v-if="loading"
          ></span>
          {{ $t("obj_health_records.lbl_save_example") }}
        </button>
      </div>
      <div class="d-flex cs-margin">
        <button
          v-if="
            !!care_team_doctor &&
            type === 'waiting-profile-details' &&
            care_team_doctor.is_accepted !== 1
          "
          :disabled="care_team_doctor.is_accepted === 1"
          class="btn btn--white px-5 rounded-sm ml-auto mr-1"
          @click="careTeamsReject()"
        >
          {{ $t("obj_health_records.lbl_reject") }}
        </button>
        <button
          v-if="
            !!care_team_doctor &&
            type === 'waiting-profile-details' &&
            care_team_doctor.is_accepted !== 1
          "
          :disabled="care_team_doctor.is_accepted === 1"
          class="btn bg-pri bd-pri text-white px-5 rounded-sm"
          @click="careTeamsAccept()"
        >
          {{ $t("obj_health_records.lbl_join") }}
        </button>
        <p
          v-if="
            !!care_team_doctor &&
            type === 'waiting-profile-details' &&
            care_team_doctor.is_accepted === 1
          "
          class="robo-16-500 txt-pri mb-0"
        >
          {{ $t("obj_health_records.lbl_joined") }}
        </p>
      </div>

      <a
        href="javascript:;"
        class="d-flex justify-content-end absolute top-3 right-0"
        @click="onClose()"
      >
        <img
          src="../../../public/assets/images/icon/x-gray.svg"
          alt
          width="26"
          height="26"
        />
      </a>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'HeaderWindow',
  props: ['title', 'type', 'care_team_doctor'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    onClose () {
      let self = this
      let user = appUtils.getLocalUser()
      let clinic = this.$globalClinic
      self.$emit('onClose')
      setTimeout(() => {
        self.sendAmplitude('Close Hồ sơ sức khỏe Window', {
          'doctor id':
            user && user.doctor && user.doctor.id ? user.doctor.id : '',
          'doctor name': user && user.name ? user.name : '',
          'workspace id': clinic && clinic.id ? clinic.id : '',
          'workspace name': clinic && clinic.name ? clinic.name : '',
          timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY')
        })
      }, 300)
    },
    getHeaderClass () {
      let type = this.type
      if (type) {
        if (type === 'health-information') {
          return 'justify-content-between'
        }
      } else {
        return 'justify-content-around'
      }
    },
    careTeamsAccept () {
      let self = this
      var r = confirm(this.$t('obj_health_records.lbl_confirm_join_hr'))
      if (r) {
        self.$emit('postCareTeamsAccept')
      }
    },
    careTeamsReject () {
      let self = this
      var r = confirm(this.$t('obj_health_records.lbl_confirm_reject_hr'))
      if (r) {
        self.$emit('postCareTeamsReject')
      }
    },
    onUpdateHealthInfo () {
      this.$router
        .push({
          path: `/doctor/diseases/${this.$route?.params?.id}/health-information/update`
        })
        .catch((_) => {})
    },
    async saveAsTemplate () {
      let self = this
      var r = confirm(this.$t('obj_health_records.lbl_confirm_save_example'))
      if (!r) return
      let params = {
        clinic_id: this.$clinicId
      }
      self.loading = true
      self.$rf
        .getRequest('DoctorRequest')
        .saveAsTemplate(self.$route?.params?.id, params)
        .then((r) => {
          self.$toast.open({
            message: this.$t('obj_health_records.lbl_save_success'),
            type: 'success'
          })
          self.$router
            .push({ path: `/doctor/diseases-templates/${r.data.id}/clone` })
            .catch((_) => {})
        })
        .finally(() => {
          self.loading = false
        })
    }
  }
}
</script>
<style lang="css" scoped>
.right-header {
  width: calc(100% - 45px) !important;
  margin-right: 15px;
}
.btn-header {
  width: 150px;
}

.cs-margin {
  margin-top: 12px;
}

@media (min-width: 768px) {
  .cs-margin {
    margin-right: 2.5rem /* 40px */;
    margin-top: 0px;
  }
}
</style>