<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light border-0 bg-white">
      <a class="navbar-brand d-lg-none" href="#">
        {{ disease_list_type == "profile-list" ? $t("obj_health_records.lbl_list_health_records") : disease_list_type == "waiting-list" ? $t("obj_health_records.lbl_list_health_records_waiting") : "" }}
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" :class="disease_list_type == 'profile-list' ? 'active d-none d-lg-flex' : ''">
            <a href="javascript:;" class="nav-link" @click="changeType('profile-list')">{{ $t("obj_health_records.lbl_list_health_records") }}</a>
          </li>
          <li class="nav-item" :class="disease_list_type == 'waiting-list' ? 'active d-none d-lg-flex' : ''">
            <a href="javascript:;" class="nav-link" @click="changeType('waiting-list')">{{ $t("obj_health_records.lbl_list_health_records_waiting") }}</a>
          </li>
        </ul>
        <button class="btn radius-0 bg-pri bd-pri text-white border-0 mr-2" type="button" @click="$emit('addPatient')"><span class="fa fa-plus mr-2"></span>Thêm bệnh nhân</button>

        <button style="background-color: inherit" v-if="can('create-careplan')" class="border bd-pri robo-16-500 txt-pri btn" type="button" @click="onShowModalCarePlan(doctorVerified)" data-toggle="tooltip-d" :title="$t('obj_health_records.bl_authentication_when_confirm')" :class="!doctorVerified ? 'inactive' : ''" data-placement="left">{{ $t("obj_health_records.lbl_create_new") }}</button>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'DiseaseListBar',
  props: ['disease_list_type'],
  data () {
    return {}
  },
  computed: {
    doctorVerified () {
      return this.$user?.doctor?.is_verified === 4
    }
  },
  mounted () {
    if (!this.doctorVerified) {
      window.$('[data-toggle="tooltip-d"]').tooltip()
    } else {
      window.$('[data-toggle="tooltip-d"]').tooltip('disable')
    }
  },
  watch: {
    $user (u) {
      if (u) {
        if (u.doctor.is_verified !== 4) {
          window.$('[data-toggle="tooltip-d"]').tooltip('enable')
        } else {
          window.$('[data-toggle="tooltip-d"]').tooltip('disable')
        }
      }
    }
  },
  methods: {
    changeType (type) {
      let self = this
      if (type === 'profile-list') {
        self.$router.push({ path: `/doctor/patient-diseases` }).catch(_ => {})
      } else if (type === 'waiting-list') {
        self.$router.push({ path: `/doctor/waiting-list` }).catch(_ => {})
      }
    },
    onShowModalCarePlan (s) {
      this.$emit('onShowModalCarePlan', s)
    }
  }
}
</script>
<style lang="css" scoped>
.normal-type {
  color: #536cbc;
}

.normal-type:hover {
  color: #20419b !important;
}

.active-border {
  border-bottom: solid 1.5px;
}

a:hover {
  text-decoration: none;
}

a[disabled] {
  /*pointer-events: none;*/
  cursor: not-allowed;
}
.nav-item.active a {
  color: #20419b !important;
}
button.inactive {
  background-color: #667085 !important;
  border-color: #667085 !important;
  color: white;
  opacity: 0.6;
  cursor: default;
}
</style>
