<template>
  <div class="">
    <div v-if="list_topics && list_topics.count && !loading">
      <div
        v-for="topic in list_topics.data"
        :key="topic.id"
        class="d-flex flex-wrap justify-content-start align-items-center w-100 mb-3 radius-10 cursor-pointer"
        style="padding: 15px"
        :class="!topic.doctor_read ? 'bg-bold-item' : 'bg-fa'"
        @click="goToDetail(topic)"
      >
        <div class="left-side-item">
          <div
            class="icon--item"
            :class="topic.doctor_read ? 'bg-bold-item' : 'bg-white'"
          >
            <img
              src="../../../public/assets/images/icon/icon-topic-diseases.svg"
              width="40"
              height="40"
              v-if="
                topic.type === 1 ||
                topic.type === 2 ||
                topic.type === 3 ||
                topic.type === 4 ||
                topic.type === 5 ||
                topic.type === 9
              "
            />
            <img
              src="../../../public/assets/images/icon/icon-topic-chat.svg"
              width="40"
              height="40"
              v-if="topic.type === 8 || topic.type === 12"
            />
            <img
              src="../../../public/assets/images/icon/icon-topic-video.svg"
              width="40"
              height="40"
              v-if="
                (topic.type === 6 || topic.type === 7) &&
                topic.attach &&
                topic.attach.method &&
                topic.attach.method == 2
              "
            />
            <img
              src="../../../public/assets/images/icon/icon-topic-walk.svg"
              width="40"
              height="40"
              v-if="
                (topic.type === 6 || topic.type === 7) &&
                topic.attach &&
                topic.attach.method &&
                topic.attach.method == 1
              "
            />
            <img
              src="../../../public/assets/images/icon/icon-topic-heart.svg"
              width="40"
              height="40"
              v-if="topic.type === 10 || topic.type === 11"
            />
          </div>
        </div>
        <div class="right-side-item">
          <div class="row mx-0">
            <div class="col-md-10 d-flex align-items-center">
              <span class="cmt-count">({{ topic.comments_count || 0 }})</span>
              <div class="flex flex-col">
                <p
                  class="mb-0 txt-black text-concat w-full"
                  :class="topic.doctor_read ? 'robo-16-400' : 'robo-16-500'"
                  style=""
                  :set="(title = getTitle(topic))"
                >
                  <span>{{ title }}</span
                  ><span v-if="title && topic.content"> - </span
                  ><span>{{ topic.content }}</span>
                </p>
                <div
                  v-if="
                    (topic.type === 6 || topic.type === 7) &&
                    topic.attach &&
                    topic.attach.method &&
                    topic.attach.method == 2 &&
                    topic.attach.consultation
                  "
                  class="cursor-pointer d-flex pdf-file align-items-center mt-1.5"
                >
                  <img
                    src="../../../public/assets/images/icon/pdf-icon.svg"
                    alt=""
                    width="24"
                    height="24"
                    class="mr-2"
                  />
                  <span class="robo-14-400">
                    {{ $t("obj_health_records.lbl_consulting_form") }}

                    {{ formatD(topic.attach.start_time) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="col-md-2 d-flex flex-wrap justify-content-end align-items-center mb-2 mb-md-0 mt-2 mt-md-0"
            >
              <p
                class="mb-0"
                :class="topic.doctor_read ? 'robo-16-400' : 'robo-16-500'"
              >
                {{ formatDMY(topic.created_at) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        :items="list_topics"
        :current_page="current_page"
        @onRefresh="onRefresh"
      ></Pagination>
    </div>
    <div class="row">
      <div
        v-if="list_topics && !list_topics.count && !loading"
        class="col-md-12 d-flex justify-content-center"
      >
        <div align="center">
          <p class="mt-3">
            <img src="../../../public/assets/images/icon/icon-new-diary.svg" />
          </p>
          <button
            class="mt-3 btn--blue btn-l d-flex justify-content-start"
            @click="onShowModalAppt()"
            style="width: 300px"
            v-if="!appUtils.isAdmin()"
          >
            <img
              src="../../../public/assets/images/icon/icon-tele-gray.svg"
              alt=""
            />
            <span class="ml-3">
              {{ $t("obj_health_records.lbl_book_appt") }}
            </span>
          </button>
          <button
            class="mb-3 mt-3 btn--blue btn-l d-flex justify-content-start"
            @click="goToCreate()"
            style="width: 300px"
            v-if="!appUtils.isAdmin()"
          >
            <img
              src="../../../public/assets/images/icon/icon-exchange-gray.svg"
              alt=""
            />
            <span class="ml-3">
              {{ $t("obj_health_records.lbl_create_new_exchange") }}
            </span>
          </button>
        </div>
      </div>
      <div class="col-12 mb-5" v-if="loading">
        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="skeleton-avatar"></div>
                </div>
                <div class="col-7">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
                <div class="col-2 ms-auto text-end">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="skeleton-avatar"></div>
                </div>
                <div class="col-7">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
                <div class="col-2 ms-auto text-end">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="skeleton-avatar"></div>
                </div>
                <div class="col-7">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
                <div class="col-2 ms-auto text-end">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="skeleton-avatar"></div>
                </div>
                <div class="col-7">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
                <div class="col-2 ms-auto text-end">
                  <div class="skeleton-line"></div>
                  <div class="skeleton-line"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '../Pagination'
import appUtils from '../../utils/appUtils.js'
export default {
  name: 'ActivityTable',
  components: { Pagination },
  props: ['topics', 'person_id', 'loading', 'current_page'],
  data () {
    return {
      list_topics: [],
      appUtils
    }
  },
  watch: {
    topics: function (_new, old) {
      this.list_topics = _new
    }
  },
  mounted () {},
  methods: {
    getTitle (topic) {
      let type = topic.type
      let attach = topic.attach
      if (type === 1 || type === 3) {
        return this.$t('obj_health_records.lbl_created_care_record')
      }
      if (type === 2 || type === 5 || type === 9) {
        return this.$t('obj_health_records.lbl_health_info_updated')
      }
      if (type === 6 || type === 7) {
        let str =
          attach.method === 2
            ? ` - ${this.$t('obj_health_records.lbl_online_consultation')}`
            : ` - ${this.$t('obj_health_records.lbl_medical_facility')}`
        let date = this.formatHMNDMY(attach.start_time)
        return date + str
      }
      if (type === 8) {
        return topic.title
      }
      if (type === 10) {
        let service_name =
          attach && attach.service_name ? attach.service_name : ''
        return `${this.$t('obj_health_records.lbl_service')} ` + service_name
      }
      if (type === 11) {
        let service_name =
          attach && attach.service_name ? attach.service_name : ''
        return (
          `${this.$t('obj_health_records.lbl_update_service_status')} ` +
          service_name
        )
      }
      if (type === 12) {
        return `${this.$t('obj_health_records.lbl_doctor_joined')}`
      }
    },
    getContent (topic) {
      let type = topic.type
      let attach = topic.attach
      if (attach && type === 10) {
        return attach.clinic_name
      }
    },
    onRefresh () {
      this.$emit('onRefresh')
    },
    onShowModalAppt () {
      this.$emit('onShowModalAppt', true)
    },
    goToCreate () {
      if (!appUtils.isAdmin()) {
        this.$router.push({
          path: `/doctor/diseases/${this.$route?.params?.id}/topic/create`
        })
      }
    },
    goToDetail (topic) {
      if (!topic) return
      if (!appUtils.isAdmin()) {
        if (!topic.doctor_read) {
          this.readTopic(topic.id)
        }
        let id = topic.id
        this.$router.push({
          path: `/doctor/diseases/${this.$route?.params?.id}/topic/${id}`
        })
      } else {
        this.$router.push({
          path: `/admin/diseases/${this.$route?.params?.id}/topic-detail?id=${topic.id}`
        })
      }
    },
    async readTopic (id) {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .readTopic(id)
        .then(() => {})
    },
    formatHMNDMY (date_time) {
      let str = window.moment(date_time).format('DD/MM/YY, HH:mm')
      return str
    },
    formatDMY (date_time) {
      return window.moment(date_time).format('DD/MM/YY HH:mm')
    },
    formatD (date_time) {
      return window.moment(date_time).format('DD/MM/YY')
    },
    openPDF (path) {
      let url = appUtils.getDocumentURL(path)
      window.open(url, '_blank').focus()
      window.event.stopPropagation()
    }
  }
}
</script>
<style lang="css" scoped="true">
.left-side-item {
  width: 56px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.right-side-item {
  width: calc(100% - 56px);
}

.icon--item {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.bg-fa {
  background-color: #fafafa;
}

.text-concat {
  width: calc(100% - 50px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-bold-item {
  background-color: #e7eaf5;
}
.pdf-file {
  background-color: #ffd6c3;
  border-radius: 6px;
  padding: 6px;
}
.cmt-count {
  width: 50px;
  text-align: center;
}
</style>